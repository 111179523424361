export const textes = {
  fr: {
    marque: "My.Offres",
    nom_entreprise_footer: "Devis.ch SA",
    facebook: "#",
    instagram: "#",
    linkedin: "#",
    url_site: "https://offres.ch",
    url_app: "https://my.offres.ch",
    email_site: "info@offres.ch",
    email_devis: "demande@offres.ch",
    avec_menu_login: false,
    url_menu_login: "https://offres.ch/",
    nom_app_mobile: "my.offres.ch",
    titre_menu_login: "Offres.ch",
    url_app_android:
      "https://play.google.com/store/apps/details?id=com.idhome.my_offres_ch",
    url_app_ios: "https://apps.apple.com/app/my-offres-ch/id1616512602",
    hero_login: "BONJOUR",
    hero_login_soustitre: "Connectez-vous à votre compte",
    login_texte_formulaire: "Vos nouveaux clients à portée de clic",
    aide_en_ligne: "Aide en Ligne",
    entreprise: "Nom de l'entreprise",
    nom: "Mon nom",
    prenom: "Mon prénom",
    email: "Email",
    forme_juridique: "Forme juridique",
    siret: "N° d'immatriculation",
    annee_creation: "Année de création",
    telephone: "Téléphone",
    ville: "Ville",
    adresse: "Adresse",
    code_postal: "Code postal",
    description_courte: "Description de mon activité",
    site_internet: "Site Internet",
    score: "Avis des Utilisateurs",
    facture_entreprise: "Devis.ch SA",
    facture_adresse_1: "Route de Champ-Colin 14",
    facture_adresse_2: "1260 Nyon",
    facture_url: "www.offres.ch",
    facture_tva: "TVA CHE-116.301.152",
    titre_notification: "Offres.ch Pro",
    cta_achat_par_stripe: false, // mettre false si non autorisé
    cta_achat_par_virement: "Commander",
    nom_fichier_cgu_fournisseur: "/CGU.pdf",
    nom_fichier_cgu_client: "/CGU.pdf",
    nom_fichier_confidentialite: "/protection_des_donnees.pdf",
    nom_fichier_manuel_utilisateur: "/manuel.pdf",
    desactivation_choix_prestation: false,
    titre_ecran_factures: "Commandes",
    soustitre_ecran_factures: "Téléchargez vos commandes",
    economie_pack_business: "Save 13.5%",
    economie_pack_premium: "Save 36%",
    nom_pack_business: "Packs crédits",
    nom_pack_premium: "Packs Premium",
    titre_menu_credit: "Recharges",
    titre_ecran_pack: "Rechargez votre pack",
    sous_titre_ecran_pack: "Contactez notre service client au 0800 04 04 04",
    detail_offres_web_url: "https://web.offres.ch/",
    cgv_offres_web_url: "https://www.offres.ch/cgv-website",
  },
};
